<template>
  <div class="container">
    <div class="content-wrapper">
      <table style="margin: 12px;" cellspacing="2" cellpadding="0" border="0">
        <tr v-for="(con, index) in data.content" :key="index">
          <td valign="top" height="35">
            <img :src="con.icon.imageUrl"
                 :alt="con.icon.alt"
                 style="width: 24px;"
            />
          </td>
          <td valign="top" width="58" align="right">{{ con.label }}</td>
          <td valign="top" v-html="con.text"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "D004",
  props: {
    data: {
      type: Object,
    },
    themeColor: {
      type: String,
      default: '#f00'
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;

  .content-wrapper {
    font-size: 17px;
    padding: 0 10px;
    box-sizing: border-box;
  }

}
</style>