<template>
  <div class="container">
    <div class="title">
      <span></span>
      {{ data.title }}
      <span></span>
    </div>
    <div class="image-wrapper" v-for="(con,index) in data.content" :key="index">
      <el-image
          :src="con.imageUrl"
          :alt="con.alt"
          loading="lazy"
          style="width: 100%;"
      />
    </div>
  </div>

</template>

<script>
export default {
  name: "D002",
  props: {
    data: {
      type: Object,
    },
    themeColor: {
      type: String,
      default: '#f00'
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;

  .title {
    width: 100%;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    span {
      display: inline-block;
      flex-grow: 1;
      height: 1px;
      border-bottom: 2px #bfbfbf solid;
    }

    span:first-child {
      margin-right: 10px;
    }

    span:last-child {
      margin-left: 10px;
    }
  }

  .image-wrapper {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

}
</style>