<template>
  <div class="container">
    <el-row>
      <el-col :span="12">
        <el-image v-if="data.imageUrl" :src="data.imageUrl" :alt="data.alt" fit="cover" loading="lazy"/>
      </el-col>
      <el-col :span="12">
        <div style="height: 100%;display: flex;justify-content: space-between; flex-direction: column;">
          <div v-html="data.topTitle"></div>
          <div v-html="data.text"></div>
          <div v-html="data.bottomTitle"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "A004",
  props: {
    data: {
      type: Object,
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">

.container {
  width: 1200px;
  margin: 40px auto;

}
</style>