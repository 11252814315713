<template>
  <div class="container">
    <div>
      <el-image
          :src="data.imageUrl"
          :alt="data.alt"
          fit="contain"
          loading="lazy"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "A010",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {

  width: 1200px;
  height: max-content;
  margin: auto;

}
</style>