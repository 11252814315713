<template>
  <div class="container">
    <div @click="goTo(data.link)">
      <el-image
          :id="data.btnID"
          :src="data.imageUrl"
          :alt="data.alt"
          fit="contain"
          loading="lazy"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "A008",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    goTo(url) {
      window.open(url)
    },
  }
}
</script>

<style scoped lang="scss">
.container {

  width: 1200px;
  height: max-content;
  margin: auto;
  cursor: pointer;

}
</style>