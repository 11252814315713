<template>
  <div class="container">
    <iframe id="frame"
            :src="data.link"
            frameBorder=0
            marginwidth=0
            marginheight=0
            scrolling="auto"
            ALLOWTRANSPARENCY="true"></iframe>
  </div>
</template>

<script>
export default {
  name: "A007",
  props: {
    data: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: space-around;

  #frame{
    width: 1200px;
    height: 800px;
    max-width: 100%;
  }
}
</style>