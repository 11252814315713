import {ElNotification} from "element-plus";

/**
 * 气泡通知
 * @param type
 * @param message
 */
export function notification(type, message) {
    ElNotification({
        message: message,
        type: type,
        offset: 100,
        duration: 1600,
    })
}
