<template>
  <div class="container">
    <div class="banner" :id="data.btnID" @click="goTo(data.link)">
      <img :src="data.imageUrl" :alt="data.alt">
    </div>
  </div>
</template>

<script>
export default {
  name: "A001",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    goTo(url) {
      if (url === '#') {
        return
      }
      window.open(url)
    },
  }
}
</script>

<style scoped lang="scss">
.container {

  width: 100%;
  height: max-content;

  .banner {
    width: 100%;
    height: 430px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}
</style>