<template>
  <div class="container">
    <component v-for="(comp, index) in comps"
               :key="index"
               :is="comp.widgets"
               :data="comp.data"
               :themeColor="data.themeColor"></component>
    <br v-for="i in 4" :key="i">
  </div>
</template>

<script>
import A001 from '../../components/langdingpage/foreground/A001.vue'
import A002 from '../../components/langdingpage/foreground/A002.vue'
import A003 from '../../components/langdingpage/foreground/A003.vue'
import A004 from '../../components/langdingpage/foreground/A004.vue'
import A005 from '../../components/langdingpage/foreground/A005.vue'
import A006 from '../../components/langdingpage/foreground/A006.vue'
import A007 from '../../components/langdingpage/foreground/A007.vue'
import A008 from '../../components/langdingpage/foreground/A008.vue'
import A009 from '../../components/langdingpage/foreground/A009.vue'
import A010 from '../../components/langdingpage/foreground/A010.vue'
import A012 from '../../components/langdingpage/foreground/A012.vue'
import D001 from '../../components/langdingpage/foreground/D001.vue'
import D002 from '../../components/langdingpage/foreground/D002.vue'
import D003 from '../../components/langdingpage/foreground/D003.vue'
import D004 from '../../components/langdingpage/foreground/D004.vue'
import D005 from '../../components/langdingpage/foreground/D005.vue'
import {ElLoading} from "element-plus";
import {request} from "@/common/rquest";
import {notification} from "@/common/notification";

export default {
  name: "preview",
  components: {
    A001,
    A002,
    A003,
    A004,
    A005,
    A006,
    A007,
    A008,
    A009,
    A010,
    A012,
    D001,
    D002,
    D003,
    D004,
    D005,
  },
  data() {
    return {
      comps: [],
      newComps: '',
      data: null,
      mark: '',
      vars: []
    }
  },
  methods: {
    // 设置页面tdk
    setPageTitle(title, keywords, description, favicon) {
      document.title = title
      document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', description)
      document.querySelector('link[name="favicon"]').setAttribute('href', favicon)
    },
    setJsCode(jsCode) {
      try {
        eval(jsCode)
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {

    // 获取标记
    this.mark = this.$route.params.mark

    // 获取URL中的所有参数
    try {
      this.vars = window.location.href.split("?")[1].split("&")
    }catch (e) {}

    let id = this.$route.params.id
    if (id) {
      // 开启页面遮罩
      const loadingInstance = ElLoading.service()

      // 获取数据
      request({
        url: '/landingpage/pageInfo/get/id/' + id
      }).then(res => {
        loadingInstance.close()

        if (res.status === 200) {
          if (res.data.code === 200) {

            this.newComps = res.data.data.data
            this.newComps = this.newComps.replaceAll("{mark}", this.mark)

            for (const item of this.vars) {
              this.newComps = this.newComps.replaceAll("{" + item.split("=")[0] + "}", item.split("=")[1])
            }

            this.comps = JSON.parse(this.newComps)
            this.data = res.data.data

            if (this.$route.params.enName !== this.data.enName) {
              this.$router.push({path: '/notfound'})
            }

            // 设置页面tdk
            this.setPageTitle(this.data.title, this.data.keywords, this.data.description, this.data.favicon)

            // 设置第三方代码
            this.setJsCode(JSON.parse(this.data.jsCode))

          } else {
            notification('error', '获取数据失败')
          }
        }
      }).catch(err => {
        loadingInstance.close()
        notification('error', '服务端出错啦，请稍后重试~')
        console.log(err);
      })
    }
  },
  /*beforeRouteEnter(to, from, next){
    console.log(to);
    console.log(from);
    next()
  }*/
}
</script>

<style scoped lang="scss">
.container {

}

</style>