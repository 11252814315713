<template>
  <div class="container">
    <div class="image-wrapper">
      <el-image
          :src="data.imageUrl"
          :alt="data.alt"
          fit="contain"
          loading="lazy"
          style="width: 100%;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "D003",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {

  width: 100%;

  .image-wrapper {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

}
</style>