<template>
  <div class="container" :style="{'width': data.width}">
    <div v-html="data.text"></div>
  </div>
</template>

<script>
export default {
  name: "A012",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {

  width: 1200px;
  height: max-content;
  margin: auto;

}
</style>