<template>
  <div class="container animate__animated animate__bounceIn">
    <div v-for="(con,index) in data.content" :key="index" style="padding-bottom: 6px;">
      <a :href="con.link" :id="con.btnID" target="_blank">
        <el-image
            :src="con.imageUrl"
            fit="contain"
            :style="{'width': '120px','height': 'max-content','background-color': themeColor}"
            loading="lazy"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "A006",
  props: {
    data: {
      type: Object,
    },
    themeColor:{
      type: String,
      default: '#f00'
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 120px;
  height: max-content;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
</style>