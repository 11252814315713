<template>
  <div class="container">
    <div class="title topic-color-bg" :style="{'background-color': themeColor}">
      {{ data.title }}
    </div>
    <div class="image-wrapper" v-for="(con,index) in data.content" :key="index">
      <el-image
          :src="con.imageUrl"
          :alt="con.alt"
          fit="contain"
          loading="lazy"
      />
    </div>
  </div>

</template>

<script>
export default {
  name: "A002",
  props: {
    data: {
      type: Object,
    },
    themeColor:{
      type: String,
      default: '#f00'
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
  margin: auto;

  .title {
    width: 100%;
    height: 79px;
    line-height: 76px;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    color: #FFFFFF;
    margin: 35px auto;
    background-image: url("https://dj.zhaozhanwang.cn/images/common/line.png");
    background-size: 100% 100%;
    background-position: center;
  }

  .image-wrapper {
    display: flex;
    justify-content: space-around;
  }

}
</style>