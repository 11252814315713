<template>
  <div class="container">
    <div :style="{'height': data.height + 'px'}"></div>
  </div>
</template>

<script>
export default {
  name: "A009",
  props: {
    data: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: max-content;
  margin: auto;
}
</style>