<template>
  <div class="container">
    <div class="sub-container">
      <div>
        <el-image
            :src="data.imageUrl"
            :alt="data.alt"
            fit="contain"
            style="width: max-content;height: 70px;"
            loading="lazy"
        />
      </div>
      <div>
        <div :style="{'color': themeColor}" v-html="data.title1"></div>
        <div v-html="data.title2"></div>
        <div v-html="data.title3"></div>
      </div>
      <div class="time-wrapper" :style="{'background-color': themeColor}">
        <span v-if="getDays(new Date(data.dateTime)) > 0">
          距离展会开幕 <strong style="color: #F00;">{{ getDays(new Date(data.dateTime)) }}</strong> 天
        </span>
        <span v-else>
          {{ data.tips }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "A005",
  props: {
    data: {
      type: Object,
    },
    themeColor:{
      type: String,
      default: '#f00'
    }
  },
  data() {
    return {}
  },
  methods: {
    // 获取指定未来日期与目前日期的相差天数
    getDays(date) {
      let future = Date.parse(date);
      let now = new Date();
      let dayNum = Math.round((future - now.getTime()) / (1000 * 3600 * 24));
      return Math.round(dayNum) > 0 ? Math.round(dayNum) : 0;
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: max-content;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  background-color: #FFFFFF;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1000;

  .sub-container {
    width: max-content;
    display: flex;
    align-items: center;

    > div {
      float: left;
    }

    > div:nth-of-type(1) {
      padding-right: 16px;
      display: flex;
      align-items: center;
    }

    > div:nth-of-type(2) {
      height: 100%;
      padding-left: 16px;
      border-left: 1px #989899 dashed;
      margin-right: 150px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .time-wrapper {
      width: 184px;
      height: 40px;
      margin-left: auto;
      font-size: 16px;
      text-align: center;
      padding: 9px;
      box-sizing: border-box;
      background-image: url("https://dj.zhaozhanwang.cn/images/common/countBox-bg.png");
    }
  }

}
</style>