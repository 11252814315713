import axios from "axios";
import {notification} from "@/common/notification";
import {ElLoading} from "element-plus";

export function request(config) {
    // 开启页面遮罩
    const loadingInstance = ElLoading.service()

    // 创建axios实例
    const instance = axios.create({
        baseURL: 'https://mp.zhaozhanwang.cn',
        data: {},
        params: {},
        timeout: 15000,
    })

    // 添加请求拦截器
    instance.interceptors.request.use(config => {
        // 如果令牌存在则携带上
        /*if (localStorage.hasOwnProperty('satoken')) {
            let satoken = localStorage.getItem('satoken')
            if (config.method === 'post'){
                config.data.satoken = satoken
            }
            config.params.satoken = satoken
        }*/

        return config;

    }, err => {
        return Promise.reject(err);
    });

    // 添加响应拦截器
    instance.interceptors.response.use(res => {
        // 关闭页面遮罩
        loadingInstance.close()

        // 状态码666表示未登录
        /*if (res.data.code === 666) {
            notification('warning', '请先登录')
            // 跳转登录页面
            window.location.href = '/#/auth/login'
        } else if (res.data.code === 500) {
            notification('error', '操作失败')
        }*/

        return res;

    }, err => {
        // 关闭页面遮罩
        loadingInstance.close()

        notification('error', '服务端错误')
        return Promise.reject(err);
    });

    return instance(config) // 指定的配置将与实例的配置合并。
}
