<template>
  <div class="container animate__animated animate__bounce" :style="{'background-color': themeColor}">
    <div :id="data.btnID" @click="goTo(data.link)">
      <el-image
          :src="data.imageUrl"
          fit="contain"
          style="width: 100%; height: 100%;"
          loading="lazy"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "D005",
  props: {
    data: {
      type: Object,
    },
    themeColor: {
      type: String,
      default: '#f00'
    }
  },
  methods: {
    goTo(url) {
      if (url === '#') {
        return
      }
      window.open(url)
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: fixed;
  right: 28px;
  bottom: 28px;
}
</style>