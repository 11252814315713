<template>
  <div class="container">
    <div class="title" :style="{'background-color': themeColor}">
      {{ data.title }}
    </div>
    <div class="content-wrapper">
      <div class="left">
        <el-image :src="data.imageUrl" :alt="data.alt" loading="lazy"/>
      </div>
      <div class="right">
        <table border="0" width="600" cellspacing="10" cellpadding="0">
          <tr v-for="(con, index) in data.content" :key="index">
            <td valign="top" width="35">
              <el-image
                  :src="con.icon.imageUrl"
                  :alt="con.icon.alt"
                  loading="lazy"
              />
            </td>
            <td width="55" valign="top">{{ con.label }}</td>
            <td valign="top" v-html="con.text"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "A003",
  props: {
    data: {
      type: Object,
    },
    themeColor:{
      type: String,
      default: '#f00'
    }
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 1200px;
  margin: auto;

  .title {
    width: 100%;
    height: 79px;
    line-height: 76px;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    color: #FFFFFF;
    margin: 35px auto;
    background-image: url("https://dj.zhaozhanwang.cn/images/common/line.png");
    background-size: 100% 100%;
    background-position: center;
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  .topic-color-bg {
    background-color: #f00;
  }

}
</style>